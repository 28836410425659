import React from "react";
import { FaReact } from "react-icons/fa";
import {
  SiJavascript,
  SiHtml5,
  SiTypescript,
  SiExpo,
  SiFirebase,
} from "react-icons/si";

const TechStack = () => {
  return (
    <div className="techStack -mt-24">
      <div class="pt-24 pb-24 w-screen flex flex-col justify-center">
        <h1 class="font-Montserrat text-6xl flex flex-row justify-center text-white">
          Tech Stack
        </h1>
        <div class="flex lg:flex-row flex-col lg:justify-around justify-center p-5 text-white font-Montserrat text-xl ">
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-react border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <FaReact class="text-6xl self-center" />
            React/ React Native
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-javascript border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <SiJavascript class="text-6xl self-center" />
            JavaScript
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-ionic border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <SiExpo class="text-6xl self-center" />
            Expo
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-html border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <SiHtml5 class="text-6xl self-center" />
            HTML
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-typescript border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <SiTypescript class="text-6xl self-center" />
            Typescript
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center text-javascript border-darkBlue rounded-lg p-8 border-4 bg-darkBlue">
            <SiFirebase class="text-6xl self-center" />
            Firebase
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStack;
