import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import { Route, Routes } from "react-router-dom";
import LoadingComponent from "./components/LoadingComponent";
import Dsgvo from "./pages/Dsgvo";
import Impressum from "./pages/Impressum";

function App() {
  const [done, setDone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 3000);
  }, []);

  return (
    <>
      {done ? (
        <div className="App bg-background">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dsgvo" element={<Dsgvo />} />
            <Route path="/impressum" element={<Impressum />} />
          </Routes>
          <Footer />
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
}

export default App;
