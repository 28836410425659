import React from "react";

const Projects = (props) => {
  return (
    <div className="projects">
      <div class="flex lg:flex-row flex-col-reverse w-screen justify-evenly">
        <div class="flex flex-col flex-1 font-Bebas text-white">
          <h1 class="text-2xl mt-10">Coming soon 😁</h1>
          <div class="bg-darkBlue mt-6 mx-6 p-12 rounded-lg">
            <div class="text-4xl font-Bebas text-white">fomo</div>
            <div class="font-Montserrat mr-10 ml-10 text-white">
              Mobile app currently in development
            </div>
          </div>
          <div class="bg-darkBlue mt-6 mx-6 p-12 rounded-lg">
            <div class="text-4xl font-Bebas text-white">Web Design</div>
            <div class="font-Montserrat mr-10 ml-10 text-white"></div>
          </div>
        </div>

        <div class="flex flex-col bg-background h-screen justify-center flex-1 font-Bebas text-white">
          <h1 class="text-9xl">Projects</h1>
        </div>
      </div>
    </div>
  );
};

export default Projects;
