import React from "react";
import { FaInstagram, FaXing, FaLinkedin } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <div className="footer">
      <div class="pt-5 pb-5 w-screen bg-darkBlue flex flex-row justify-evenly">
        <div class="flex lg:flex-col flex-col lg:justify-evenly justify-center p-2 text-white text-4xl">
          <div class="mt-2 flex flex-col justify-center hover:text-yellow-400 cursor-pointer">
            <FaInstagram />
          </div>
          <div class="mt-5 flex flex-col justify-center hover:text-yellow-400 cursor-pointer">
            <FaXing />
          </div>
          <div class="mt-5 flex flex-col justify-center hover:text-yellow-400 cursor-pointer">
            <FaLinkedin />
          </div>
          <div class="mt-5 flex flex-col justify-center hover:text-yellow-400 cursor-pointer">
            <GoMail />
          </div>
        </div>
        <div class="flex lg:flex-col flex-col lg:justify-evenly justify-center p-2 text-white text-4xl">
          <h2 class="font-Bebas text-4xl">Jonas Pundt</h2>
          <p class="font-Raleway text-2xl">Brookstraße 15</p>
          <p class="font-Raleway text-2xl">49692 Cappeln</p>
          <p class="font-Raleway text-2xl">+49 171 9978937</p>
        </div>
      </div>
      <div class="w-screen bg-darkBlue flex flex-row justify-center text-white font-Roboto p-2">
        <Link to="/impressum">
          <p class="mr-5">Impressum</p>
        </Link>
        <Link to="/dsgvo">
          <p>DSGVO</p>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
