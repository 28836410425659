import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const About = (props) => {
  return (
    <div className="about">
      <div>
        <div class="flex lg:flex-row flex-col w-screen justify-evenly">
          <div class="flex flex-col h-screen justify-center flex-1 font-Bebas text-white">
            <h1 class="text-9xl">About</h1>
            <p class="text-2xl">Summary</p>
            <p class="text-2xl">Education</p>
            <p class="text-2xl">Hobbies</p>
            <p class="text-2xl">Branding</p>
          </div>
          <div class="flex flex-col flex-1 bg-background text-white">
            <div class="bg-darkBlue mt-6 mx-6 p-12 rounded-lg">
              <div class="text-4xl font-Bebas text-white">Summary</div>
              <div class="font-Montserrat mr-10 ml-10 text-white">
                I am a web/ mobile developer and UI designer from Germany.
              </div>
            </div>
            <div class="mt-6 mx-6 p-12 rounded-lg bg-darkBlue">
              <div class="text-4xl font-Bebas text-white">Education</div>
              <div class="font-Montserrat mr-10 ml-10 text-justify">
                <ul class="list-outside list-disc">
                  <li>
                    Fullstack Developer at{" "}
                    <a
                      href="https://www.amcon.de/"
                      class="underline decoration-mail"
                    >
                      Amcon
                    </a>{" "}
                    with focus on Web and Mobile Development
                  </li>
                  <li>
                    Frontend Developer at{" "}
                    <a
                      href="https://www.sla.de/"
                      class="underline decoration-mail"
                    >
                      SLA
                    </a>{" "}
                    in Quakenbrück
                  </li>
                  <li>
                    Apprenticeship at{" "}
                    <a
                      href="https://www.blg-logistics.com/"
                      class="underline decoration-mail"
                    >
                      BLG Logistics
                    </a>{" "}
                    in Bremen as a Software Developer
                  </li>
                  <li>Gymnasium - Abitur</li>
                </ul>
              </div>
            </div>
            <div class="bg-darkBlue mt-6 mx-6 p-12 rounded-lg mb-6">
              <div class="text-4xl font-Bebas">Hobbies</div>
              <div class="font-Montserrat text-justify mr-10 ml-10">
                In my freetime I like to go to the gym. Also I like to make
                music. I play the guitar and the piano. Besides I am a huge
                football and American Football Fan. My favorite football team is
                Werder Bremen and my favorite NFL team are the Seattle Seahawks.
                Also I love to travel and take photos. Either landscape or
                portrait photos. And I will never say no to good food.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
