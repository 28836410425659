import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, icon }) => {
  return (
    <div class="fixed right-8 bottom-8 text-white text-2xl hover:bg-mail shadow-lg flex justify-center items-center bg-yellow-400 rounded-full w-10 h-10">
      <Link
        to="#"
        onClick={(e) => {
          window.location = mailto;
          e.preventDefault();
        }}
      >
        {icon}
      </Link>
    </div>
  );
};

export default ButtonMailto;
