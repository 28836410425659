import React from "react";
import Typewriter from "typewriter-effect";
import pc from "../assets/developer-team.svg";
import { GoMail } from "react-icons/go";
import ButtonMailto from "../components/ButtonMailTo";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Services from "../components/Services";
import TechStack from "../components/TechStack";
import memoji from "../assets/memoji.png";
AOS.init({
  duration: 1000,
  delay: 200,
});

const Home = () => {
  return (
    <div className="home bg-background">
      <div class="flex flex-col lg:flex-row w-screen h-screen bg-darkBlue rounded-br-full">
        <div data-aos="fade-down" class="basis-1/2 text-left">
          <h4 class="font-Raleway text-white lg:ml-20 ml-10 mt-20 text-4xl">
            Hi! My name is
          </h4>
          <h1 class="font-Staatliches text-white text-9xl ml-10 lg:ml-20">
            Jonas Pundt
          </h1>
          <div class="font-Raleway text-white lg:text-7xl text-3xl ml-10 lg:ml-20">
            <Typewriter
              options={{
                strings: [
                  "Web Development",
                  "Mobile Development",
                  "Web Design",
                  "UI/ UX Design",
                  "Branding",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
        <div data-aos="fade-left" class="basis-1/2">
          <img src={pc} class="m-20" alt=""></img>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <div
          class="flex flex-col lg:flex-row mt-12 mb-12 bg-about p-6 lg:p-24 w-3/4 lg:-mt-24 rounded-2xl"
          data-aos="fade-up"
        >
          <div class="lg:w-1/2 self-center">
            <img src={memoji} class="object-cover w-80" alt="" />
          </div>
          <div class="m-auto lg:w-1/2">
            <h1 class="font-Raleway text-white overline decoration-yellow-400 text-7xl">
              About
            </h1>
            <p class="mt-5 font-Raleway text-justify text-xl text-white">
              I design and develop apps and websites. Also I do the branding and
              logo design for you. Since you landed here, you are probably
              looking for someone who can build your website or app. Either a
              new project from scratch or an existing project. Get in touch with
              me and let's start building your project.
            </p>
            <Link to="/about">
              <button class="border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-white border-2 border-solid w-56 font-Roboto rounded-lg mt-5 text-2xl">
                MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" class="">
        <Services />
      </div>
      <div data-aos="fade-up" class="">
        <TechStack />
      </div>
      <ButtonMailto mailto="mailto:jonas.pundt@gmail.com" icon={<GoMail />} />
    </div>
  );
};

export default Home;
