import React from "react";
import branding from "../assets/Services/branding.png";
import web from "../assets/Services/daten.png";
import landing from "../assets/Services/landing-page.png";
import mobile from "../assets/Services/mobile-entwicklung.png";
import ui from "../assets/Services/ui-design.png";

const Services = () => {
  return (
    <div className="services -mt-24">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#1a2238"
          fill-opacity="1"
          d="M0,256L80,240C160,224,320,192,480,197.3C640,203,800,245,960,266.7C1120,288,1280,288,1360,288L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
      <div class="pt-8 pb-8 w-screen bg-darkBlue flex flex-col justify-center">
        <h1 class="font-Montserrat text-6xl text-white flex flex-row justify-center">
          Services
        </h1>
        <div class="flex lg:flex-row flex-col lg:justify-around justify-center p-5 text-white font-Montserrat text-xl">
          <div class="lg:w-56 mt-2 flex flex-col justify-center">
            <img src={ui} class="w-20 self-center" alt="" />
            UI/ UX Design
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center">
            <img src={web} class="w-20 self-center" alt="" />
            Web Development
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center">
            <img src={mobile} class="w-20 self-center" alt="" />
            Mobile Development
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center">
            <img src={landing} class="w-20 self-center" alt="" />
            Landing Pages
          </div>
          <div class="lg:w-56 mt-5 flex flex-col justify-center">
            <img src={branding} class="w-20 self-center" alt="" />
            Branding
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#1a2238"
          fill-opacity="1"
          d="M0,96L60,106.7C120,117,240,139,360,133.3C480,128,600,96,720,117.3C840,139,960,213,1080,224C1200,235,1320,181,1380,154.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
    </div>
  );
};

export default Services;
