import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { init, sendForm } from "emailjs-com";
init("user_dCUFxzLhA57Zt4HX16eHa");

// TODO: add validation
// https://placidowang.medium.com/how-to-add-an-email-form-to-your-react-website-for-free-using-emailjs-and-react-hook-form-7267d6365291

const Contact = () => {
  const [contactNumber, setContactNumber] = useState("000000");
  const generateContactNumber = () => {
    const numStr = "000000" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => {
    const form = document.querySelector("#contact-form");
    generateContactNumber();
    sendForm("default_service", "template_80iy375", "#contact-form").then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        form.reset();
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  };
  return (
    <div className="contact">
      <h1 className="mt-10 text-3xl font-Montserrat text-white">
        Have an exciting project where you need some help?
      </h1>
      <h1 className="text-3xl font-Montserrat text-white">
        Or an idea for a new project?
      </h1>
      <h1 className="mb-10 text-3xl font-Montserrat text-white">
        Send me a message and let's chat.
      </h1>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          class="w-1/2 h-12 rounded-md p-2"
          name="user_name"
          placeholder="Name"
          {...register("user_name", { required: true })}
        />
        <br />
        <input
          type="email"
          class="w-1/2 h-12 mt-2 rounded-md p-2"
          name="user_email"
          placeholder="Email"
          {...register("user_email", { required: true })}
        />
        <br />
        <textarea
          name="message"
          class="w-1/2 h-36 mt-2 rounded-md p-2"
          placeholder="Message"
          {...register("message", { required: true })}
        />
        <br />
        <input type="hidden" name="contact_number" value={contactNumber} />
        <button
          class="border-yellow-400 mb-6 text-yellow-400 hover:bg-yellow-400 hover:text-white border-2 border-solid w-56 font-Roboto rounded-lg mt-5 text-2xl"
          type="submit"
          value="Send"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default Contact;
