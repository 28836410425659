// TODO: Links zu Logos einfügen
// <a href="https://www.flaticon.com/de/kostenlose-icons/mobile-entwicklung" title="mobile entwicklung Icons">Mobile entwicklung Icons erstellt von feen - Flaticon</a>
// <a href="https://www.flaticon.com/de/kostenlose-icons/sich-anpassendes-design" title="sich anpassendes design Icons">Sich anpassendes design Icons erstellt von Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/de/kostenlose-icons/daten" title="daten Icons">Daten Icons erstellt von Freepik - Flaticon</a>
// <a href="https://www.flaticon.com/de/kostenlose-icons/branding" title="branding Icons">Branding Icons erstellt von max.icons - Flaticon</a>
// <a href="https://www.flaticon.com/de/kostenlose-icons/zielseite" title="zielseite Icons">Zielseite Icons erstellt von Freepik - Flaticon</a>

import React from "react";

const Impressum = () => {
  return (
    <div class="flex-col h-screen text-white text-Bebas">
      <div class="text-4xl my-5">Impressum</div>
      <h2>Angaben gemäß § 5 TMG</h2>
      <div class="my-5">
        <h2>Jonas Pundt</h2>
        <h2>Brookstraße 15</h2>
        <h2>49692 Cappeln</h2>
      </div>
      <div class="my-5">
        <h2 class="text-2xl">Kontakt</h2>
        <h2>Telefon: +491719978937</h2>
        <h2>Email: jonas.pundt@gmail.com</h2>
      </div>

      <div class="my-5 flex-col">
        <h1 class="text-2xl">Logo Quellen:</h1>
        <p>
          <a
            href="https://www.flaticon.com/de/kostenlose-icons/mobile-entwicklung"
            title="mobile entwicklung Icons"
          >
            Mobile entwicklung Icons erstellt von feen - Flaticon
          </a>
        </p>
        <p>
          <a
            href="https://www.flaticon.com/de/kostenlose-icons/sich-anpassendes-design"
            title="sich anpassendes design Icons"
          >
            Sich anpassendes design Icons erstellt von Freepik - Flaticon
          </a>
        </p>
        <p>
          <a
            href="https://www.flaticon.com/de/kostenlose-icons/daten"
            title="daten Icons"
          >
            Daten Icons erstellt von Freepik - Flaticon
          </a>
        </p>
        <p>
          <a
            href="https://www.flaticon.com/de/kostenlose-icons/branding"
            title="branding Icons"
          >
            Branding Icons erstellt von max.icons - Flaticon
          </a>
        </p>
        <p>
          <a
            href="https://www.flaticon.com/de/kostenlose-icons/zielseite"
            title="zielseite Icons"
          >
            Zielseite Icons erstellt von Freepik - Flaticon
          </a>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
